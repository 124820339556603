import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, map, Observable, tap } from 'rxjs';
import { CHUNK_SIZE, ProtectedSignURLResponse, UploadPart } from '../models/picture';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private progressSubject = new BehaviorSubject<number>(0); // Initial progress is 0%

  public uploadProgress$ = this.progressSubject.asObservable(); // Expose observable for subscription

  // Method to reset progress
  public resetProgress(): void {
      this.progressSubject.next(0);
  }

  public updateProgress(percentage: number): void {
      this.progressSubject.next(percentage);
  }


  // Get current progress
  public getProgress(): number {
       return this.progressSubject.getValue(); // Return the current progress value
  }

  private http = inject(HttpClient);

  public uploadMultipartFile(file: File, UrlPresigned: string[]): Observable<UploadPart[]> {
      const fileSize = file.size;
      const NUM_CHUNKS = Math.floor(fileSize / CHUNK_SIZE) + 1;
      const progressIncrement = Math.floor(70 / NUM_CHUNKS); // Spread remaining 70% over all chunks
      const uploadPartsArray: UploadPart[] = [];
      const orderData: ProtectedSignURLResponse[] = [];

      const uploadRequests: Observable<any>[] = [];
      let currentProgress = 20; // Start progress after second API call (20%)

      for (let index = 1; index <= NUM_CHUNKS; index++) {
          const start = (index - 1) * CHUNK_SIZE;
          const end = (index < NUM_CHUNKS) ? start + CHUNK_SIZE : fileSize;
          const blob = file.slice(start, end);

          orderData.push({
              signedUrl: UrlPresigned[index - 1],
              partNumber: index
          });

          const headersCustom = new HttpHeaders().set('skip_auth', 'true');
          const req = new HttpRequest('PUT', UrlPresigned[index - 1], blob, { headers: headersCustom });

          const uploadRequest = this.http.request(req).pipe(
               tap((event) => {
                  if (event instanceof HttpResponse && event.status === 200) {
                      const currentPresigned = orderData.find(item => item.signedUrl === event.url);
                      uploadPartsArray.push({
                          ETag: event.headers.get('ETag').replace(/[|&;$%@"<>()+,]/g, ''),
                          PartNumber: currentPresigned.partNumber
                      });

                    // Update progress after each chunk upload
                    currentProgress += progressIncrement;
                    this.progressSubject.next(Math.min(currentProgress, 90));
                  }
                  return event;
              }),
              catchError(error => {
                  console.error('Error uploading chunk:', error);
                  throw error;
              })
          );

          uploadRequests.push(uploadRequest);
      }

      // After all parts are uploaded, set progress to 90%
      return forkJoin(uploadRequests).pipe(
          tap(() => this.updateProgress(90)), // 90% after all parts uploaded
          map(() => uploadPartsArray)
      );
  }
}
