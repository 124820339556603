import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { successCreateParentFamilies, successDeleteParentFamilies, successUpdateParentFamilies } from '../actions/parent-family.action';
import { successCreateFamilies, successDeleteFamilies, successUpdateFamilies } from '../actions/family.action';
import { successCreateAssets, successDeleteAssets, successUpdateAssets } from '../actions/asset.actions';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, of, switchMap } from 'rxjs';
import { contextIsValid$, selectUserSelectedContext$ } from '../selectors/filter-context.selectors';
import { CacheService } from 'src/app/services/cache.service';
import { ActionTypes as RightActionTypes, SuccessDeleteMyRights } from '../actions/user-right.action';
import { AssetEffects } from './asset.effects';
import { successCreateSites, successDeleteSites, successUpdateSites } from '../actions/site.action';
import { successCreateCompanies, successDeleteCompanies, successUpdateCompanies } from '../actions/company.actions';
import { successUpdateZone } from '../actions/zone.action';
import { ObjectStore } from 'src/app/models/cache';
import { ActionTypes as FamilyRightActionTypes, SuccessUpdateFamilyRight } from '../actions/familyRight.action';
import { noop } from '../actions/_shared.action';
import { addFamilyProfile } from '../actions/profile.action';
import { selectFamilyById$ } from '../selectors/family.selectors';

@Injectable()
export class CacheEffects {
    //#region Injections
    private actions$ = inject(Actions);
    private store = inject(Store);
    private cacheService = inject(CacheService);
    private assetEffects = inject(AssetEffects);
    //#endregion

    //#region Company
    public companyCreate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successCreateCompanies),
            switchMap(({ payload }) => this.cacheService.createData$(ObjectStore.Company, ...payload)),
            map(() => noop()),
        );
    });

    public companyUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successUpdateCompanies),
            switchMap(({ payload }) => this.cacheService.updateData$(ObjectStore.Company, ...payload)),
            map(() => noop()),
        );
    });

    public companyDelete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successDeleteCompanies),
            switchMap(({ payload }) => this.cacheService.deleteData$(ObjectStore.Company, ...payload.map(({ id }) => id))),
            map(() => noop()),
        );
    });
    //#endregion

    //#region Site
    public siteCreate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successCreateSites),
            switchMap(({ payload }) => this.cacheService.createData$(ObjectStore.Sites, ...payload)),
            map(() => noop()),
        );
    });

    public siteUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successUpdateSites),
            switchMap(({ payload }) => this.cacheService.updateData$(ObjectStore.Sites, ...payload)),
            map(() => noop()),
        );
    });

    public siteDelete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successDeleteSites),
            switchMap(({ payload }) => this.cacheService.deleteData$(ObjectStore.Sites, ...payload.map(({ id }) => id))),
            map(() => noop()),
        );
    });
    //#endregion

    //#region Parent families
    public parentFamilyCreate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successCreateParentFamilies),
            switchMap(({ family }) => combineLatest([
                of(family),
                this.store.select(contextIsValid$),
                this.store.select(selectUserSelectedContext$),
            ])),
            filter(([, valid, context ]) => valid && !context?.parentFamilies?.length && !context?.families?.length),
            switchMap(([family]) => this.cacheService.createData$(ObjectStore.ParentFamilies, family)),
            map(() => noop()),
        )
    });

    public parentFamilyUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successUpdateParentFamilies),
            switchMap(({ payload }) => this.cacheService.updateData$(ObjectStore.ParentFamilies, ...payload)),
            map(() => noop()),
        );
    });

    public parentFamilyDelete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successDeleteParentFamilies),
            switchMap(({ payload }) => this.cacheService.deleteData$(ObjectStore.ParentFamilies, ...payload.map(({ id }) => id))),
            map(() => noop()),
        );
    });

    public parentFamilyRightDelete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType<SuccessDeleteMyRights>(RightActionTypes.SUCCESS_DELETE_MY_RIGHT),
            filter(({ payload }) => payload.removeRight.isParentFamily),
            switchMap(({ payload }) => combineLatest([
                this.cacheService.deleteData$(ObjectStore.ParentFamilies, payload.removeRight.deleteMyRight.id),
                this.cacheService.deleteData$(
                    { table: ObjectStore.FamilyRight, index: 'parentFamilyId' },
                    payload.removeRight.deleteMyRight.id
                ),
            ])),
            map(() => noop()),
        );
    });
    //#endregion

    //#region Families
    public familyCreate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successCreateFamilies),
            switchMap(({ family }) => combineLatest([
                of(family),
                this.store.select(contextIsValid$),
                this.store.select(selectUserSelectedContext$),
            ])),
            filter(([, valid, context ]) => valid && !context?.families?.length),
            switchMap(([ family ]) => this.cacheService.createData$(ObjectStore.Families, family)),
            map(() => noop()),
        );
    });

    public familyUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successUpdateFamilies),
            switchMap(({ payload }) => this.cacheService.updateData$(ObjectStore.Families, ...payload)),
            map(() => noop()),
        );
    });

    public familyDelete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successDeleteFamilies),
            switchMap(({ payload }) => {
                const idsToDelete = payload.map(({ id }) => id);
                return combineLatest([
                    this.cacheService.deleteData$(ObjectStore.Families, ...idsToDelete),
                    this.cacheService.deleteData$(ObjectStore.FamilyRight, ...idsToDelete),
                ]);
            }),
            map(() => noop()),
        );
    });

    public familyRightDelete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType<SuccessDeleteMyRights>(RightActionTypes.SUCCESS_DELETE_MY_RIGHT),
            filter(({ payload }) => !payload.removeRight.isParentFamily),
            switchMap(({ payload }) => combineLatest([
                this.cacheService.deleteData$(ObjectStore.Families, payload.removeRight.deleteMyRight.id),
                this.cacheService.deleteData$(ObjectStore.FamilyRight, payload.removeRight.deleteMyRight.id),
            ])),
            map(() => noop()),
        );
    });
    //#endregion

    //#region Assets
    public assetCreate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successCreateAssets),
            // Filter out the assets which are not in the current context
            switchMap(({ payload }) => combineLatest(payload.map(
                (asset) => this.assetEffects.isAssetInContext$(asset).pipe(
                    map(inContext => inContext ? asset : null)
                )
            ))),
            map(assets => assets.filter(v => !!v)),
            switchMap(assets => this.cacheService.createData$(ObjectStore.Assets, ...assets)),
            map(() => noop()),
        );
    });

    public assetUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successUpdateAssets),
            // Distinguish the assets which are or are not in the current context
            switchMap(({ payload }) => combineLatest(payload.map(
                (asset) => this.assetEffects.isAssetInContext$(asset).pipe(
                    map(inContext => inContext ? { asset, action: 'update' } :  { asset, action: 'delete' })
                )
            ))),
            switchMap((assets) => combineLatest([
                // Update the assets in the current context
                this.cacheService.updateData$(ObjectStore.Assets, ...assets.filter(({ action }) => action === 'update').map(({ asset }) => asset)),
                // Remove the assets out of the current context
                this.cacheService.deleteData$(ObjectStore.Assets, ...assets.filter(({ action }) => action === 'delete').map(({ asset }) => asset.id)),
            ])),
            map(() => noop()),
        );
    });

    public assetDelete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successDeleteAssets),
            switchMap(({ payload }) => this.cacheService.deleteData$(ObjectStore.Assets, ...payload.map(({ id }) => id))),
            map(() => noop()),
        );
    });
    //#endregion

    //#region Zones
    public zoneUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(successUpdateZone),
            switchMap(({ zone }) => this.cacheService.updateData$(ObjectStore.Zone, zone)),
            map(() => noop()),
        );
    });
    //#endregion

    //#region Rights
    public familyRightCreate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(addFamilyProfile),
            switchMap(({ profileFamily }) => this.store.select(selectFamilyById$(profileFamily.id)).pipe(
                switchMap((family) => this.cacheService.createData$(
                    ObjectStore.FamilyRight,
                    {
                        id: profileFamily.id,
                        level: profileFamily.level,
                        name: family.name,
                        parentFamilyId: profileFamily.parent,
                        scope: family.scope,
                    }
                )),
            )),
            map(() => noop()),
        )
    });

    public familyRightUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType<SuccessUpdateFamilyRight>(FamilyRightActionTypes.SUCCESS_UPDATE_FAMILY_RIGHT),
            switchMap(({ payload }) => this.cacheService.updateData$(ObjectStore.FamilyRight, payload)),
            map(() => noop()),
        );
    });
    //#endregion

    //#region Contexts
    /**
     * The context detection change is done in the filter-context.effects.ts due to asynchonous issues.
     */
    //#endregion
}
