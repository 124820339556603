import { UserRights } from './../../../models/profile';
import { DialogValidationComponent, ValidationDialogData } from './../../shared/dialog/dialog-validation/dialog-validation.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Certificate, MachineStatus, MachineStatusProgress } from './../../../models/certificate';
import { Store, Action } from '@ngrx/store';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    acceptCertificateRequest,
    cancelCertificateDeletion,
    deleteCertificateRequest,
    fetchCertificate,
    refuseCertificateRequest,
    reinstallCertificate,
    renewCertificateRequest,
} from 'src/app/store/actions/certificate.action';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { selectCertificates$, selectCertificatesLoading$ } from 'src/app/store/selectors/certificate.selectors';
import { selectProfileUserRights$ } from '../../../store/selectors/profile.selectors';
import { DialogCreateCertificateRequestComponent } from '../dialog/dialog-create-certificate/dialog-create-certificate-request.component';
import { CertificateEffects } from 'src/app/store/effects/certificate.effects';
import * as moment from 'moment';
import { selectCertificatesFilters$ } from 'src/app/store/selectors/filter.selectors';
import { DialogCertificateTotpComponent } from '../dialog/dialog-certificate-totp/dialog-certificate-totp.component';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, startWith, take, tap } from 'rxjs/operators';
import { detailExpand, rotate } from '../../shared/animations';
import { Banner, BannerService } from '../../../services/banner.service';
import { DialogCertificateStatusProgressDetailedComponent } from '../status-progress/dialog-detailed/dialog-detailed.component';

export enum CertificateActions {
    Renew = 'renew',
    AcceptRequest = 'acceptRequest',
    RefuseRequest = 'refuseRequest',
    Delete = 'delete',
    CancelDeletion = 'cancelDeletion',
    Reinstall = 'reinstall'
}

type TableData = Certificate & {
    focalPointName?: Certificate['focalPoint']['name'],
    focalPointEmail?: Certificate['focalPoint']['email'],
    focalPointPhone?: Certificate['focalPoint']['phoneNumber'],
};

@Component({
    selector: 'app-manage-certificates',
    templateUrl: './manage-certificates.component.html',
    styleUrls: ['./manage-certificates.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [detailExpand, rotate(180)],
})
export class ManageCertificatesComponent implements OnInit, OnDestroy {
    @ViewChild(MatSort, { static: true }) private _sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) private _paginator: MatPaginator;

    public displayedColumns: Array<string> = ['machineId', 'status', 'newStatus', 'lastConnexion', 'certificateExpirationDate', 'comment', 'actions'];
    public expandedColumns: Array<string> = ['phoneNum', 'phoneModel', 'contactName', 'contactPhoneNum', 'contactMail', 'company',
        'focalPointName', 'focalPointEmail', 'focalPointPhone'];
    public dataSource = new MatTableDataSource<TableData>();
    public loader = true;
    public writeRight = false;
    public MachineStatus = MachineStatus;
    public CertificateActions = CertificateActions;
    public view = 'certificatesView';
    public certificates$: Observable<Array<Certificate>>;
    public loading$ = this.store.select(selectCertificatesLoading$).pipe(
        tap(() => this._cd.markForCheck()),
    );

    private _pendingWarningDisplayed = false;
    private _expandedRows: Certificate[] = [];
    private _subscription: Subscription = new Subscription();
    private _pendingApprovalBanner: Banner;

    constructor(
        private _cd: ChangeDetectorRef,
        private _translate: TranslateService,
        private store: Store,
        private _dialog: MatDialog,
        private _certificateEffects: CertificateEffects,
        private _route: ActivatedRoute,
        private _router: Router,
        private _bannerService: BannerService,
    ) { }

    ngOnInit() {
        this._initRights();
        this._initData();
        this._initTable();
        this._route.queryParamMap.subscribe(
            params => {
                if (params.get('totp') !== null) {
                    this.dataSource.connect().pipe(
                        filter(certificates => certificates.length === 1),
                        take(1),
                    ).subscribe(
                        ([certificate]) => this.openTotpDialog(certificate)
                    );
                }
                // Remove totp parameter if it was set
                this._router.navigate([], {
                    relativeTo: this._route,
                    queryParams: {
                        totp: null,
                    },
                    queryParamsHandling: 'merge',
                    replaceUrl: true,
                });
            }
        );
        this._subscription.add(
            this.dataSource.connect().subscribe(
                () => this._cd.markForCheck()
            )
        );
    }

    /**
     * Initialize the userRights to show or not the edit / create buttons
     */
    private _initRights(): void {
        // Check if the user is a mobile manager
        this._subscription.add(this.store.select(selectProfileUserRights$).subscribe((userRights: UserRights) => {
            this.writeRight = userRights.isMobileAdmin;
        }));
    }

    /**
     * Initialize the certificates data needed to display the table
     */
    private _initData() {
        this.store.dispatch(fetchCertificate());
        this.certificates$ = this.store.select(selectCertificates$);
        this._subscription.add(this.store.select(selectCertificatesFilters$).subscribe((certificates) => {
            // Remove the expanded rows which are not in the data
            this._expandedRows = this._expandedRows.filter(
                ({ id }) => !!certificates.find(certificate => certificate.id === id)
            );
            const startDate = moment();
            this.dataSource.data = certificates.map((element): TableData => {
                const data: TableData = element;
                if (element.certificateExpirationDate) {
                    const endDate = moment(data.certificateExpirationDate);
                    data.delay = Math.trunc(moment.duration(endDate.diff(startDate)).asDays());
                }
                if (element.focalPoint) {
                    data.focalPointName = element.focalPoint.name;
                    data.focalPointEmail = element.focalPoint.email;
                    data.focalPointPhone = element.focalPoint.phoneNumber;
                }
                const dataSourceCertificate = this.dataSource.data.find(
                    ({ id }) => id === data.id
                  ) ?? {};
                  // Keep reference (avoid the collapse of the item when the datSource is set)
                  return Object.assign(dataSourceCertificate, data);

            });
            // Display warn snackbar forever if status1 is "onHold"
            if (!this._pendingWarningDisplayed && this.writeRight && certificates.find(element => element.status?.status1.toLowerCase() === MachineStatus.OnHold)) {
                this._pendingWarningDisplayed = !this._pendingWarningDisplayed;
                const bannerSub = this._translate.onLangChange.pipe(
                    startWith(this._translate.currentLang),
                ).subscribe(
                    () => {
                        if (this._pendingApprovalBanner) {
                            this._bannerService.dismiss(this._pendingApprovalBanner);
                        }
                        this._pendingApprovalBanner = {
                            type: 'warning',
                            message: this._translate.instant('CERTIFICATE_PENDING_VALIDATION'),
                            action: {
                                text: this._translate.instant('SEE_THEM'),
                                callback: this._showPendingApprovals.bind(this),
                            }
                        };
                        this._bannerService.addBanner(this._pendingApprovalBanner);
                        this._pendingApprovalBanner.dismissed$.subscribe(
                            () => {
                                this._pendingApprovalBanner = null;
                                bannerSub.unsubscribe();
                            }
                        );
                    }
                );
                this._subscription.add(bannerSub);
            }
            setTimeout(() => {
                this.loader = false;
            }, 0);
        }));
    }

    /**
     * Initialize the material table
     */
    private _initTable() {
        // Apply paginator and sort
        this.dataSource.paginator = this._paginator;
        this.dataSource.sort = this._sort;
        // Column sorting
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'status1': return item?.status?.status1;
                case 'status2': return item?.status?.status2;
                case 'status3': return item?.status?.status3;
                case 'status4': return item?.status?.status4;
                default: return item[property];
            }
        };
    }

    private _showPendingApprovals() {
        const dialog = this._dialog.open<DialogValidationComponent, ValidationDialogData>(DialogValidationComponent, {
            data: {
                textContent: this._translate.instant('CONFIRM_RELOAD'),
                title: this._translate.instant('CONFIRM'),
            },
        });
        dialog.componentInstance.onValidateEvent.subscribe(
            async () => {
                const pendingCertificates = await firstValueFrom(this.certificates$.pipe(
                    map(certificates => certificates.filter(
                        ({ status }) => status.status1 === MachineStatus.OnHold
                    )),
                ));
                await this._router.navigate([], {
                    relativeTo: this._route,
                    queryParams: {
                        machineId: pendingCertificates.map(
                            ({ machineId }) => machineId
                        ),
                    },
                    queryParamsHandling: 'merge',
                });
                window.location.reload();
            }
        )
    }

    /**
     * Return the translated column name
     * @param value The technical attribute name
     */
    public getColumnName(value: string) {
        switch (value) {
            case 'status':
                return this._translate.instant('ACTIVE_CERT_STATUS');
            case 'newStatus':
                return this._translate.instant('NEW_CERT_STATUS');
            default:
                return this._translate.instant(value.toUpperCase());
        }
    }

    /**
     * Scroll up on material table
     */
    public scrollUp() {
        const table = document.querySelector('.way-table');
        table.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);
    }

    public openProgressDetails(status: MachineStatusProgress, expiration: string, event: Event) {
        event.stopImmediatePropagation();
        this._dialog.open(DialogCertificateStatusProgressDetailedComponent, {
            data: {
                ...status,
                expiration,
            },
        });
    }

    /**
     * Open the update or create dialog
     * @param element Optional param, needed for edit mode only, containing the certificate table line
     */
    public openUpdateCreateDialog(element?: Certificate, dialogConfig: Partial<MatDialogConfig> = {}) {
        const dimensions: Pick<MatDialogConfig, "minWidth"|"width"|"maxWidth"> = {
            minWidth: '300px',
            width: '50vw',
            maxWidth: '900px',
        };
        if (element) { // Edit mode
            this._dialog.open(DialogCreateCertificateRequestComponent, {
                data: element,
                ...dimensions,
                ...dialogConfig,
            });
        } else { // Create mode
            this._dialog.open(DialogCreateCertificateRequestComponent, {
                ...dimensions,
                ...dialogConfig,
            });
        }
    }

    /**
     * Open a confirmation dialog to add the focal point
     * @param certificate The certificate to add focal point
     * @param title The title of the warning popup
     */
    private _requireFocalPoint(certificate: Certificate, title: string) {
        const dialogRef = this._dialog.open(DialogValidationComponent, {
            minWidth: '250px',
            data: {
                title: title,
                textContent: this._translate.instant('M2M_FILL_FOCAL_POINT'),
                validateText: this._translate.instant('DIALOG_UNDERSTOOD_OPTION'),
                cancelText: this._translate.instant('CANCEL'),
            }
        });
        dialogRef.componentInstance.onValidateEvent.subscribe(() => {
            this.openUpdateCreateDialog(certificate, {
                autoFocus: 'input[name="focalPointName"]',
            });
            dialogRef.close();
        });
    }

    /**
     * Open the confirm dialog after clicking on renew, delete, accept or refuse request
     * @param action The action from which the method is called (renew, delete, accept, refuse request)
     * @param element The certificate table line
     */
    public openConfirmDialog(action: string, element: Certificate) {
        const dialogData: ValidationDialogData = {
            title: '', // Title to display in the popin
            textContent: '', // Text content to display in the popin
            validateText: this._translate.instant('DIALOG_VALIDATE_OPTION'),
            cancelText: this._translate.instant('CANCEL'),
        };
        let reduxAction: Action; // Redux action to call when validating in the popin
        // Assign title, textContent, reduxAction & payload on generic confirm dialog depending on "action" parameter
        switch (action) {
            case CertificateActions.Renew:
                dialogData.title = this._translate.instant('CERTIFICATE_RENEW_POPIN_TITLE');
                if (!element.focalPoint) {
                    this._requireFocalPoint(element, dialogData.title);
                    return;
                }
                dialogData.textContent = this._translate.instant('CERTIFICATE_RENEW_POPIN_CONTENT');
                reduxAction = renewCertificateRequest({ payload: element });
                break;
            case CertificateActions.Delete:
                dialogData.title = this._translate.instant('CERTIFICATE_DELETE_POPIN_TITLE');
                dialogData.textContent = this._translate.instant('CERTIFICATE_DELETE_POPIN_CONTENT');
                dialogData.dangerousAction = true;
                reduxAction = deleteCertificateRequest({ payload: element.id });
                break;
            case CertificateActions.AcceptRequest:
                dialogData.title = this._translate.instant('CERTIFICATE_ACCEPT_REQUEST');
                if (!element.focalPoint) {
                    this._requireFocalPoint(element, dialogData.title);
                    return;
                }
                dialogData.textContent = this._translate.instant('CERTIFICATE_ACCEPT_REQUEST_POPIN_CONTENT');
                reduxAction = acceptCertificateRequest({ payload: [{ machineId: element.machineId }] });
                break;
            case CertificateActions.RefuseRequest:
                dialogData.title = this._translate.instant('CERTIFICATE_REFUSE_REQUEST');
                dialogData.textContent = this._translate.instant('CERTIFICATE_REFUSE_REQUEST_POPIN_CONTENT');
                reduxAction = refuseCertificateRequest({ payload: [{ machineId: element.machineId }] });
                break;
            case CertificateActions.CancelDeletion:
                dialogData.title = this._translate.instant('CERTIFICATE_CANCEL_DELETION_POPIN_TITLE');
                dialogData.textContent = this._translate.instant('CERTIFICATE_CANCEL_DELETION_POPIN_CONTENT');
                reduxAction = cancelCertificateDeletion({ payload: element.id });
                break;
            case CertificateActions.Reinstall:
                dialogData.title = this._translate.instant('CERTIFICATE_REINSTALL_POPIN_TITLE');
                dialogData.textContent = this._translate.instant('CERTIFICATE_REINSTALL_POPIN_CONTENT');
                reduxAction = reinstallCertificate({ payload: element.id });
                break;
            default:
                break;
        }
        const dialogRef = this._dialog.open(DialogValidationComponent, {
            minWidth: '250px',
            data: dialogData,
        });
        dialogRef.componentInstance.onValidateEvent.subscribe(() => {
            // Dynamically dispatch specific redux action with specific payload depending on the reduxAction & payload property from switch case
            this._subscription.add(this._certificateEffects.effectSubject.subscribe(() => { dialogRef.close(); }));
            this.store.dispatch(reduxAction);
        });
    }

    /**
     * Stores the expansion state of the row
     * @param row The tracker
     */
   public switchRowExpansion(row: Certificate): void {
    const index = this._expandedRows.findIndex(
      certificate => certificate.id === row.id
    );
    if (index > -1) {
      this._expandedRows.splice(index, 1);
    } else {
      this._expandedRows.push(row);
    }
  }

  /**
   * Get if the row has been expanded
   * @param row The row of the table
   * @returns The row is expanded
   */
  public isRowExpanded(row: Certificate): boolean {
    return !!this._expandedRows.find(
      ( {id} ) => row.id === id
    );
  }

    public openTotpDialog(certificate: Certificate): void {
        this._dialog.open(DialogCertificateTotpComponent, {
            data: certificate,
        });
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
        this._pendingApprovalBanner && this._bannerService.dismiss(this._pendingApprovalBanner);
    }
}
